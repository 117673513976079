.image-container img {
  max-height: 240px;
  margin: 0 auto;
}

.clear {
  clear: both;
}

.details,
.title {
  text-align: center;
}

.error {
  margin-top: 5px;
  font-family: monospace;
  font-size: 14px;
  color: red;
}

.image-container {
  align-items: center;
  display: flex;
  width: 85%;
  height: 80%;
  margin: 15px 10px 10px 37px;
  text-align: center;
}

.preview-container {
  /* height: 335px; */
  width: 100%;
  margin-bottom: 10px;
}

.placeholder-preview,
.render-preview {
  text-align: center;
  background-color: #f2f2f2;
  padding: 14px 0;
  width: 100%;
  border-radius: 5px;
  border: 2px dashed #ccc;
  cursor: pointer;
  min-height: 150px;
}
.placeholder-preview p {
  font-size: 18px;
  letter-spacing: 1.2px;
  text-transform: 'capitalize';
  font-weight: bolder;
  color: #999;
  opacity: 0.65;
}

.upload-container {
  cursor: pointer;
  height: 350px;
}
