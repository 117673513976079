*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary: #8e278f;
  --purpleBackground: #e0dce9;
  --deepPurple: #413962;
  --pinkTint2: #f2d9e8;
  --lightGreen: #d1e4a8;
  --green: #28a745;
  --red: #dc3545;
  --grey5: #e0e0e0;
  --grey4: #bdbdbd;
  --grey3: #828282;
  --grey2: #4f4f4f;
  --grey1: #333333;
  --purpleTint1: #cd7fcd;
  --yellow: #fbdb7d;
  --orange: #ff8871;
  --background: #e0dce9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #efe6f4;
}

html {
  /* font-size: 62.5%; */
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: white;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 1rem;
}

/* Handle */

::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background-color: var(--purpleBackground);
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
}

h1,
h2,
h3,
h4,
p {
  line-height: 140%;
  margin-bottom: 0 !important;
}

.app-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 720px) {
  h1 {
    font-size: 3em;
    font-weight: 400;
  }

  h2 {
    font-size: 1.75em;
    font-weight: 700;
  }

  h3 {
    font-size: 1.5em;
    font-weight: 600;
  }

  h4 {
    font-size: 1.25em;
    font-weight: 600;
  }
  p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 720px) {
  h1 {
    font-size: 1.75em;
    font-weight: 400;

    /* margin-bottom: 2em; */
  }

  h2 {
    font-size: 1.25em;
    font-weight: 700;
  }

  h3 {
    font-size: 1.125em;
    font-weight: 600;
  }

  h4 {
    font-size: 1em;
    font-weight: 600;
  }
  p {
    font-size: 0.875em;
  }
}

/* MEDIA QUERY MANAGER */

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
/* @media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
} */
