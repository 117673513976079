.landingPageContainer {
  width: 100%;
  padding: 0 60px;
  background-color: #fff;
}

.landingPageContainer>.layer1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landingPageContainer>.layer1>.section1 {
  width: 42%;
  height: 500;
  z-index: 10;
}

.landingPageContainer>.layer1>.section1>h1 {
  font-family: 'Bodoni Moda', serif;
  font-size: 56px;
  padding: 50px 0 10px 0;
}

.landingPageContainer>.layer1>.section1>p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

.landingPageContainer>.layer1>.section1>a>img {
  width: 170px;
  height: 50px;
  margin-top: 25px;
  cursor: pointer;
}

.landingPageContainer>.layer1>.section2 {
  width: 50%;
  height: 500px;
  position: relative;
}

.landingPageContainer>.layer1>.section2 .leftImageContainer {
  width: 50%;
  height: 320px;
  overflow: hidden;
}

.landingPageContainer>.layer1>.section2 .leftImageContainer>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center -240px;
}

.landingPageContainer>.layer1>.section2 .rightImageContainer {
  width: 50%;
  height: 480px;
  overflow: hidden;
}

.landingPageContainer>.layer1>.section2 .rightImageContainer>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center -110px;
}

.landingPageContainer>.layer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landingPageContainer>.layer2>.section3 {
  width: 42%;
  height: 500px;
}

.landingPageContainer>.layer2>.section3>.content {
  width: calc(100% + 60px);
  margin-left: -60px;
  height: 500px;
  padding: 70px 0 0 60px;
  background-color: #F2D9E8;
  border-radius: 0 40px 40px 0;
}

.landingPageContainer>.layer2>.section3>.content>.imgContainer {
  width: 80%;
  height: 800px;
}

.landingPageContainer>.layer2>.section3>.content>.imgContainer>img {
  width: 100%;
  height: 100%;
  object-fit: contain
}

.landingPageContainer>.layer2>.section4 {
  width: 50%;
  height: 500px;
  display: flex;
  align-items: center;
}

.landingPageContainer>.layer2>.section4>.content>h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
}

.landingPageContainer>.layer2>.section4>.content>p {
  padding: 5px 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

.landingPageContainer>.layer3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landingPageContainer>.layer3>.section5 {
  margin: 50px 0;
  width: 42%;
  height: 465px;
}

.landingPageContainer>.layer3>.section6 {
  margin: 50px 0;
  width: 50%;
  height: 465px;
}

.landingPageContainer>.layer3>.section6>.container {
  width: calc(100% + 150px);
  margin-left: -90px;
  height: 465px;
  border-radius: 40px 0 0 40px;
  background-color: #F2D9E8;
  padding: 0 60px 0 90px;
  display: flex;
  align-items: center;
}

.landingPageContainer>.layer3>.section6>.container>.content>h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
}

.landingPageContainer>.layer3>.section6>.container>.content>p {
  padding: 20px 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}

.input-container {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#btn {
  margin-left: 20px;
  border-radius: 50px;
  height: 50px;
  width: 200px;
  min-width: 200px;
  padding: 0, 10px;
  border: none;
  outline: 0;
  font-size: 15px;
  background-color: var(--primary) !important;
  color: #fff;
}

@media (max-width: 1250px) {

  .landingPageContainer>.layer1>.section2 .leftImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center -200px;
  }
}

@media (max-width: 1150px) {

  .landingPageContainer>.layer1>.section2 .leftImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center -170px;
  }

  .landingPageContainer>.layer1>.section2 .rightImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center -100px;
  }
}

@media (max-width: 1050px) {

  .landingPageContainer>.layer1>.section2 .leftImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center -150px;
  }

  .landingPageContainer>.layer1>.section2 .rightImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center -90px;
  }
}

@media (max-width: 960px) {
  .landingPageContainer {
    width: 100%;
    padding: 0 24px;
  }

  .landingPageContainer>.layer1 {
    display: flex;
    flex-direction: column;
  }

  .landingPageContainer>.layer1>.section1 {
    width: 100%;
    height: 500;
  }

  .landingPageContainer>.layer1>.section2 {
    margin: 30px 0 30px 0;
    width: 100%;
    height: 500px;
  }

  .landingPageContainer>.layer1>.section2 .leftImageContainer {
    width: 50%;
    height: 500px;
    overflow: hidden;
  }

  .landingPageContainer>.layer1>.section2 .leftImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 100px;
  }

  .landingPageContainer>.layer1>.section2 .rightImageContainer {
    width: 48%;
    height: 500px;
    overflow: hidden;
  }

  .landingPageContainer>.layer1>.section2 .rightImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 10px;
  }

  .landingPageContainer>.layer2 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .landingPageContainer>.layer2>.section3 {
    width: 100%;
    height: 660px;
    position: relative;
  }

  .landingPageContainer>.layer2>.section3>.content {
    position: absolute;
    bottom: 0;
    width: calc(100% + 48px);
    margin-left: -24px;
    height: 350px;
    padding: 0;
    background-color: #F2D9E8;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .landingPageContainer>.layer2>.section3>.content>.imgContainer {
    max-width: 350px;
    height: auto;
    overflow: hidden;
  }

  .landingPageContainer>.layer2>.section3>.content>.imgContainer>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }

  .landingPageContainer>.layer2>.section4 {
    width: 100%;
    margin: 25px 0 30px 0;
    height: auto;
    display: flex;
    align-items: center;
  }

  .landingPageContainer>.layer3 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: 0;
  }

  .landingPageContainer>.layer3>.section5 {
    display: none;
  }

  .landingPageContainer>.layer3>.section6 {
    margin: 0 0 0 -24px;
    width: calc(100% + 48px);
    height: auto;
    background-color: #F2D9E8;
  }

  .landingPageContainer>.layer3>.section6>.container {
    max-width: 100%;
    padding: 50px 24px;
    margin: 0;
    height: auto;
    border-radius: 0;
    background-color: #F2D9E8;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 750px) {
  .landingPageContainer>.layer1>.section2 {
    margin: -50px 0 30px 0;
    width: 100%;
    height: 500px;
  }

  .landingPageContainer>.layer1>.section2 .leftImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 140px;
  }

  .landingPageContainer>.layer1>.section2 .rightImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 90px;
  }
}

@media (max-width: 600px) {
  .input-container {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  #btn {
    margin: 20px 0 0 0;
    border-radius: 50px;
    height: 50px;
    width: 100%;
    min-width: 200px;
    padding: 0, 10px;
    border: none;
    outline: 0;
    font-size: 15px;
    background-color: var(--primary) !important;
    color: #fff;
  }

  .landingPageContainer>.layer1>.section2 {
    margin: -80px 0 30px 0;
    width: 100%;
    height: 500px;
  }

  .landingPageContainer>.layer1>.section2 .leftImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center 200px;
  }

  .landingPageContainer>.layer1>.section2 .rightImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center 140px;
  }
}
