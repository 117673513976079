.bgContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* width: 100vw; */
}

.container-header {
  padding-top: 10px;
  background-color: var(--background);
  padding: 70px 20px 20px 100px;
}

.container-content {
  padding: 20px 100px 20px 100px;
  background-color: white;
}
