.col-title {
  color: var(--deepPurple);
  font-size: 17px;
  font-weight: 700;
  width: 100%;
  text-align: 'center';
}

.col-verified {
  width: 70px;
  height: 30px;
  border: none;
  color: #fff;
  background-color: #8e278f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px 0 #555;
}