.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2a1f42;
  padding: 0 60px;
}

.footer>.upperSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
}

.footer>.upperSection>.leftMenu {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.footer>.upperSection>.leftMenu li {
  list-style: none;
  margin-right: 80px;
}

.footer>.upperSection>.rightMenu {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.footer>.upperSection>.rightMenu>a>img {
  width: 120px;
  object-fit: contain;
}

.footer>.footerDivider {
  width: 100%;
  border-top: 1px solid #ccc;
}

.footer>.lowerSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.footer>.lowerSection>.mainIcon>img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.footerText {
  color: white;
}

@media (max-width: 960px) {

  .footer {
    padding: 0 24px;
  }

  .footer>.upperSection {
    align-items: flex-end;
  }

  .footer>.upperSection>.leftMenu {
    flex-direction: column;
  }

  .footer>.upperSection>.leftMenu li {
    padding-top: 15px;
  }

  .footer>.lowerSection {
    height: 120px;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer>.lowerSection>.socialIcons {
    margin-left: -10px;
  }
}