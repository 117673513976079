.container {
  max-width: 100vw;
  background-color: #f2f2f2;
  display: block;
}

.policy-wrapper {
  margin: auto;
  word-wrap: break-word;
  max-width: 890px;
  padding: 56px 100px;
  background-color: #fff;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 720px) {
  .policy-wrapper {
    padding: 20px;
  }
}
