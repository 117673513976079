#onboarding {
  /* background-color: red; */
  display: flex;
  flex-grow: 1;
}

.onboarding_left {
  width: 320px;
  /* background-color: aliceblue; */
}

.onboarding_right {
  /* background-color: aqua; */
  width: 100%;
}

.basic .radio_button {
  margin-right: 1rem;
}

.basic .inputs {
  margin: 24px 0;
}

.basic .basic_files {
  display: flex;
  justify-content: space-between;
  max-width: 625px;
  align-items: center;
  margin: 24px 0;
}

.active {
  background-color: var(--pinkTint2);
  color: var(--primary);
  border-left: 4px solid var(--primary);
}

.onboarding_left > p {
  height: 40px;
}

@media only screen and (max-width: 814px) {
  .onboarding_left {
    display: none;
    /* background-color: aliceblue; */
  }
  .current_title {
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 814px) {
  .none {
    display: none;
  }
}
