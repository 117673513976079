.category-navbar {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  max-width: 260px;
  background-color: #f2f2f2;
  position: fixed;
  z-index: 10;
  padding-bottom: 90px;
}

.fixed-back-btn {
  background-color: #f2f2f2;
  z-index: 10;
}
.box-shadow {
  box-shadow: 0 2px 6px -2px rgba(119, 119, 119, 0.605);
}

@media screen and (max-width: 960px) {
  .category-navbar {
    max-width: 220px;
  }
}

@media screen and (max-width: 700px) {
  .category-navbar {
    max-width: 180px;
  }
}
