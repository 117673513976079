.categories-grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 30px;
  justify-content: center;
}

.list-section {
  flex: 1.5;
  padding-top: 10px;
  padding-bottom: 30px;
  margin-left: 290px;
}
@media screen and (max-width: 960px) {
  .categories-grid {
    grid-template-columns: repeat(2, auto);
  }
  .list-section {
    flex: 1.5;
    padding-top: 10px;
    margin-left: 240px;
  }
}
@media screen and (max-width: 700px) {
  .categories-grid {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    align-items: center;
  }
  .list-section {
    flex: 1.5;
    padding-top: 10px;
    margin-left: 200px;
  }
}
