.form-control-custom {
  box-sizing: border-box;
  margin-bottom: 15px;
  width: 100%;
}
.form-action {
  width: 100%;
}
.form-input {
  width: 100%;
}
.form-input .label {
  font-weight: 400;
  display: block;
  font-size: 16px;
  color: var(--grey1);
  align-self: center;
  letter-spacing: 0.2px;
}

.form-input input,
textarea {
  outline: none;
  width: 100%;
  padding: 7px 10px;
  font-size: 16px;
  color: var(--grey2);
  border-radius: 5px;
  border: 1px solid var(--grey4);
  resize: none !important;
  font-weight: 350;
  overflow: auto;
  transition: all 0.2s ease;
}

.form-input input[type='file'] {
  display: none;
}

.form-input input:focus,
.form-input textarea:focus {
  box-shadow: 0 0 01px 1.05px var(--primary);
}
.form-control-custom .formError {
  color: red;
  margin-top: 5px;
  font-size: 12px;
  font-family: monospace;
  text-align: left;
}
.inputError {
  border: 2px solid 'red';
}

@media screen and (max-width: 650px) {
  .form-input input,
  .textarea {
    border: 1px solid var(--grey2);
  }
  .formError {
    font-size: 14px;
  }
  .font-medium {
    font-size: 20px;
  }
}
