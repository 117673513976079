.data-input .label {
  width: 30%;
  font-size: 16.3px;
  align-self: center;
  font-weight: bold;
}

.data-input .value {
  width: 70%;
  align-self: center;
}

.data-input .span {
  width: 6.5px;
  background-color: black;
  border: 1px solid black;
}
