.background {
  background-color: #f2f2f2;
}
.bread-crumb {
  width: 100%;
  height: 270px;
  margin-bottom: calc(-200px);
  padding: 1.5rem;
  font-size: 0.8rem;
  background-color: #e0dce9;
}
.content-card {
  background-color: #fff;
  max-width: 1088px;
  border-radius: 10px;
  padding: 24px;
}

.content {
  max-width: 1088px;
  margin: 0 auto;
  text-align: left;
}

.background_title {
  color: var(--deepPurple);
  font-size: 48px;
  margin-bottom: 14px;
}

@media only screen and (max-width: 768px) {
  .background_title {
    font-size: 24px;
    margin-left: 10px;
  }
}
