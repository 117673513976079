.accordion-box {
  padding: 15px 15px;
  width: 100%;
  border-radius: 4px;
  border: 1.5px solid var(--grey5);
  border-left: 4.5px solid var(--primary);
}

.accordion-box .line {
  height: 20px;
  width: 1.8px;
  background-color: var(--grey5);
  align-self: center;
}
