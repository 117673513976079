.logo-image {
  height: 80%;
}

.header {
  background-color: #fff;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 62px;
}

.menu-wrapper {
  z-index: 20;
  position: relative;
  box-shadow: 0 0 5px 0 #aaa;
  background-color: #fff;
  padding: 10px 24px 0 24px;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.header li a {
  display: block;
  color: black;
  padding: 20px 20px;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
}

.header .logo {
  color: #fff;
  display: block;
  float: left;
  font-size: 1.5em;
  padding: 12px 20px;
  text-decoration: none;
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 0 28px 10px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #000;
  display: block;
  height: 2px;
  position: relative;
  transition: all 0.2s ease-out;
  width: 18px;
  margin: auto 0;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #000;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 4px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked~.menu {
  max-height: 340px;
}

.header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* Responsive */
@media only screen and (max-width: 960px) {
  .header ul {
    background-color: #fff;
    text-align: center;
    /* padding: 15px 0; */
  }

  .header li a {
    padding: 15px 0;
    /* border-bottom: 1px dotted #ddd; */
  }

  #navigationButton {
    width: 60%;
    margin: 10px 0 15px 0;
  }
}

@media only screen and (min-width: 960px) {
  #navigationButton {
    margin-right: 10px;
  }

  .menu-wrapper {
    background: #fff;
    height: 72px;
    line-height: 72px;
    width: 100%;
    z-index: 3;
    padding: 0 60px;
  }

  .header li {
    float: left;
  }

  .header .logo {
    line-height: 1;
  }

  .header li a {
    color: #000;
    padding: 0px 50px;
  }

  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}