.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 197, 202, 0.487);
  z-index: 0;
  backdrop-filter: blur(2.5px);
  visibility: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: all 0.1s ease-in-out;
}

.show-overlay {
  opacity: 1;
  transform: translateY(0);
  transform: scale(1.2);
  z-index: 1500;
  visibility: visible;
}

.modal-container {
  max-width: 850px;
  max-height: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.16s ease-in-out;
}

.show-modal {
  opacity: 1;
  visibility: visible;
}
.cancel {
  color: 'red';
  position: absolute;
  top: 1.5rem;
  right: 4rem;
  cursor: pointer;
}
