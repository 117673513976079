.flex {
  display: flex;
}

.flex-row {
  display: flex;
  gap: 20px;
}

.flex-row-rev {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-y-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-y-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.flex-y-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.flex-y-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.flex-y-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-y-btw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-x-start {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.flex-x-end {
  display: flex;
  align-items: 'flex-end';
  gap: 20px;
}

.flex-x-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-x-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}

.flex-x-btw-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.flex-x-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.flex-x-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}

.margin-b-xs {
  margin-bottom: 5px;
}

.margin-b-s {
  margin-bottom: 15px;
}

.margin-b-m {
  margin-bottom: 40px;
}

.margin-b-l {
  margin-bottom: 70px;
}

.margin-b-xl {
  margin-bottom: 120px;
}

.margin-t-xs {
  margin-top: 5px;
}

.margin-t-s {
  margin-top: 15px;
}

.margin-t-m {
  margin-top: 40px;
}

.margin-t-l {
  margin-top: 70px;
}

.margin-t-xl {
  margin-top: 120px;
}

.m-left-s {
  margin-left: 20px;
}

.m-left-m {
  margin-left: 45px;
}

.m-left-l {
  margin-left: 65px;
}

.m-left-xl {
  margin-left: 95px;
}

.m-right-xs {
  margin-right: 8px;
}

.m-right-s {
  margin-right: 20px;
}

.m-right-m {
  margin-right: 45px;
}

.m-right-l {
  margin-right: 65px;
}

.m-right-xl {
  margin-right: 95px;
}

.m-0 {
  margin: 0;
}

.pos-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.transition {
  transition: all 0.2s linear;
}

.transition-slow {
  transition: all 0.65s ease;
}

.alert-custom {
  position: fixed;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
}

.show-alert {
  opacity: 1;
  z-index: 1500;
  transform: translate(-50%, -50%) scale(1);
}

label+.css-144oh30-MuiInputBase-root-MuiInput-root {
  margin-top: 0px !important;
}

.css-1cak895-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(135, 132, 132, 0.45) !important;
}

.css-12wnh03-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(135, 132, 132, 0.45) !important;
}

@media screen and (max-width: 960px) {
  .remove-flex-900 {
    display: block;
  }
}